export const random = {
    number: (upTo) => {
        return Math.floor(Math.random() * upTo);
    },
    color: () => {
        return `hsl(${random.number(255)}, 70%, 60%)`;
    },
    objKey: (obj) => {
        let keys = Object.keys(obj);
        return keys[random.number(keys.length)];
    },
};
