<script lang="ts">
	import { fade, fly } from "svelte/transition";

	export let visible: boolean;
	export let hide: () => void;
</script>

{#if visible}
	<div class="overlay">
		<div
			class="background"
			transition:fade={{ duration: 250 }}
			on:click={hide}
		/>
		<div class="content" transition:fly={{ y: 50, duration: 250 }}>
			<button on:click={hide}>&#10005;</button>
			<p>
				Europa lebt von der Vielfalt seiner Regionen, Sprachen und Nationen –
				Grenzen und Zugehörigkeiten haben sich im Laufe der europäischen
				Geschichte immer wieder verändert. Das zeigt sich ganz anschaulich an
				den Familienbiographien verschiedener Schülerinnen und Schüler unserer
				Schule, dem Karolinen-Gymnasium im bayerischen Rosenheim. Die Karte gibt
				Auskunft über die Herkunftsorte der Eltern und Großeltern unserer
				Schülerinnen und Schüler – dabei ergibt sich eine Art Netz, das sich
				über viele Regionen und Länder des Kontinents erstreckt.
			</p>
		</div>
	</div>
{/if}

<style>
	.overlay {
		position: fixed;
		top: 0;
		left: 0;

		z-index: 60000;

		width: 100vw;
		height: 100vh;

		display: grid;
		place-items: center;
		padding: 1rem;

		overflow-y: scroll;
	}

	.background {
		background: #000a;

		position: fixed;
		top: 0;
		left: 0;

		width: 100vw;
		height: 100vh;

		z-index: 75000;
	}

	.content {
		background: #fff;
		box-shadow: var(--shadow);

		z-index: 100000 !important;

		border-radius: 1rem;
		padding: 1rem;
		max-width: 768px;
	}

	button {
		cursor: pointer;

		float: right;

		background: none;
		border: none;
		padding: 0;

		color: red;
		font-size: 2rem;
	}

	p {
		margin: 0;
		z-index: 100000 !important;
	}
</style>
