<script lang="ts">
	import { get } from "svelte/store";
	import { maps } from "../data/maps";
	import { people } from "../data/people";
	import { currentPerson, currentMap } from "../data/stores";
	import Overlay from "./Overlay.svelte";
	let explanation_visible: boolean = false;
	const hide = () => (explanation_visible = false);
</script>

<nav>
	<h1>schools beyond borders map</h1>
	<select
		value={get(currentPerson)}
		on:change={(event) => {
			// @ts-ignore
			currentPerson.set(event.target.value);
		}}
	>
		{#each Object.keys(people) as key}
			<option>{key}</option>
		{/each}
	</select>
	<select
		value={get(currentMap)}
		on:change={(event) => {
			// @ts-ignore
			currentMap.set(event.target.value);
		}}
	>
		{#each Object.keys(maps) as key}
			<option>{key}</option>
		{/each}
	</select>
	<button id="show" on:click={() => (explanation_visible = true)}>?</button>
</nav>
<Overlay {hide} visible={explanation_visible} />

<style>
	nav {
		height: 5rem;
		display: flex;
		gap: 1rem;
		padding: 0.5rem 2rem;
		align-items: center;
		overflow-x: auto;
		position: fixed;
		top: 0;
		left: 0;
		width: 100vw;
		z-index: 50000;
		background: #fff;
		box-shadow: var(--shadow);
	}

	h1 {
		margin: 0;
	}

	@media (max-width: 768px) {
		h1 {
			display: none;
		}
	}

	select,
	button {
		background: #ddd;
		border: none;
		border-radius: 0.5rem;
		cursor: pointer;
	}

	select {
		font-size: 1rem;
		padding: 0.25rem;
		padding-left: 0.5rem;
	}

	button {
		border-radius: 50%;
		flex-shrink: 0;
	}

	#show {
		width: 2.5rem;
		font-size: 1.5rem;
		padding: 0;
		height: 2.5rem;
	}
</style>
