export const people = {
    Leo: [
        {
            label: "Aiglsbach",
            location: [48.691869, 11.708708],
        },
        {
            label: "Landshut",
            location: [48.540481, 12.151545],
        },
        {
            label: "Essen",
            location: [51.453297, 7.008807],
            children: [
                {
                    label: "Leipzig",
                    location: [51.3406321, 12.3747329],
                },
                {
                    label: "Schlesien",
                    location: [51.205439, 16.156897],
                },
            ],
        },
    ],
    Matthias: [
        {
            label: "Marktheidenfeld (Unterfranken)",
            location: [49.8452797, 9.6067197],
        },
        {
            label: "Göttingen",
            location: [51.5328328, 9.9351811],
            children: [
                {
                    label: "Stettin (heutiges Polen)",
                    location: [53.416218, 14.549478],
                    children: [
                        {
                            label: "Legnica",
                            location: [51.205439, 16.156897],
                        },
                    ],
                },
                {
                    label: "Breslau (heutiges Polen)",
                    location: [51.108978, 17.032669],
                    children: [
                        {
                            label: "Riga (heutiges Polen)",
                            location: [56.949398, 24.105185],
                        },
                        {
                            label: "Danzig (heutiges Polen)",
                            location: [54.361434, 18.628218],
                        },
                    ],
                },
            ],
        },
    ],
    Johanna: [
        {
            label: "Westfalen",
            location: [51.481758, 7.224549],
            images: [
                {
                    name: "Johanna/Grosseltern_Mutter",
                    description: "Westfalia (region of birth of maternal grandparents)",
                },
            ],
        },
        {
            label: "Oberbayern",
            location: [48.240583, 11.969915],
            children: [
                {
                    label: "Sudetenland (heute Tschechien)",
                    location: [49.817492, 15.472962],
                    images: [
                        {
                            name: "Johanna/Luftansicht",
                            description: "Aerial view",
                        },
                        {
                            name: "Johanna/Grosseltern_Vater",
                            description: "region of birth of paternal grandparents (today)",
                        },
                        {
                            name: "Johanna/Grosseltern_Vater_1909",
                            description: "region of birth of paternal grandparents (1909)",
                        },
                        {
                            name: "Johanna/Haus",
                            description: "their house",
                        },
                    ],
                },
            ],
        },
    ],
    Finn: [
        {
            label: "Lippstadt",
            location: [51.674707, 8.347194],
            children: [
                {
                    label: "Oeseteiden",
                    location: [51.565103, 8.425426],
                },
                {
                    label: "Hamm",
                    location: [51.673858, 7.815982],
                },
            ],
        },
        {
            label: "Wittenberg",
            location: [51.866653, 12.646761],
            children: [
                {
                    label: "Hemberg",
                    location: [47.300466, 9.175659],
                },
                {
                    label: "Hohen Erlitz",
                    location: [50.181784, 16.553936],
                },
            ],
        },
    ],
    Jaromir: [
        {
            label: "Nové Město na Moravě, Tschechien",
            location: [49.566934, 16.083507],
            images: [
                {
                    name: "Jaromir/Vater",
                    description: "here his father grew up",
                },
            ],
            children: [
                {
                    label: "Prag, Tschechien",
                    location: [50.083372, 14.416804],
                    images: [
                        {
                            name: "Jaromir/Orchester",
                            description: "orchestra in Prague, in which his grandparents both played and met each other",
                        },
                    ],
                },
                {
                    label: "Vsetin, Tschechien",
                    location: [49.333184, 17.999879],
                },
            ],
        },
        {
            label: "Wolfenbüttel, Deutschland",
            location: [52.16589, 10.533599],
            images: [
                {
                    name: "Jaromir/Mutter",
                    description: "here his mother grew up",
                },
                {
                    name: "Jaromir/Heirat",
                    description: "marriage of the grandparents",
                },
            ],
            children: [
                {
                    label: "Stettin, Polen",
                    location: [53.416218, 14.549478],
                    images: [
                        {
                            name: "Jaromir/Opa",
                            description: "grandpa (as a student)",
                        },
                    ],
                },
                {
                    label: "Lodz, Polen",
                    location: [51.750153, 19.466741],
                    images: [
                        {
                            name: "Jaromir/Oma",
                            description: "grandma as a child (with great-grandma)",
                        },
                    ],
                },
            ],
        },
    ],
    Veronika: [
        {
            label: "Schytomyr, Ukraine",
            location: [50.25983, 28.669235],
            images: [
                {
                    name: "Veronika/Schytomyr",
                    description: "",
                },
            ],
        },
        {
            label: "Almaty, Kasachstan",
            images: [
                {
                    name: "Veronika/Almaty",
                    description: "",
                },
            ],
            location: [43.236392, 76.945728],
            children: [
                {
                    label: "Odessa, Ukraine",
                    location: [46.487319, 30.739278],
                },
                {
                    label: "Trubnyy, Russland",
                    location: [46.387745, 48.544494],
                },
            ],
        },
    ],
    Dion: [
        {
            label: "Lubavec-Skenderaj (Kosovo)",
            location: [42.7965703, 20.8488728],
            children: [
                {
                    label: "Gushavc (Kosovo)",
                    location: [42.8781693, 20.8285206],
                },
            ],
        },
        {
            label: "Zahaq-Pejë (Kosovo)",
            location: [42.6564455, 20.3857914],
            children: [
                {
                    label: "Vusanje (Montenegro)",
                    location: [42.5266292, 19.8459712],
                },
            ],
        },
    ],
};
