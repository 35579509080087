import L from "leaflet";
import { get } from "svelte/store";
import { maps } from "../data/maps";
import { people } from "../data/people";
import { currentMap, currentPerson, isSafari } from "../data/stores";
import { random } from "./random";
import "leaflet-swoopy";
const home = [47.86114, 12.1257];
export default class Renderer {
    constructor() {
        this.layers = [];
        // Create map
        this.map = L.map("map");
        this.map.setView(home, 5);
        this.tileLayer = L.tileLayer(get(currentMap));
        this.tileLayer.addTo(this.map);
        this.setPerson(people[get(currentPerson)]);
        // Add subscriptions
        currentPerson.subscribe((value) => {
            this.setPerson(people[value]);
        });
        currentMap.subscribe((value) => {
            this.tileLayer.setUrl(maps[value]);
        });
    }
    setPerson(migrations) {
        // clear map
        this.layers.forEach((layer) => {
            this.map.removeLayer(layer);
        });
        this.layers = [];
        this.addMigration({
            label: "Rosenheim",
            location: home,
            children: migrations,
        });
    }
    render(layer) {
        this.layers.push(layer);
        layer.addTo(this.map);
    }
    addMigration(migration) {
        if (migration.images) {
            let marker = L.marker(migration.location, {
                title: migration.label,
            });
            this.render(marker);
            let div = document.createElement("div");
            migration.images.forEach((image) => {
                div.append(this.createImage(image));
            });
            marker.bindPopup(div);
        }
        if (!migration.children)
            return;
        migration.children.forEach((m) => {
            this.render(
            //@ts-ignore
            L.swoopyArrow(m.location, migration.location, {
                iconAnchor: [20, 10],
                iconSize: [20, 16],
                factor: 0.8,
                weight: 2,
                arrowFilled: true,
                color: random.color(),
            }));
            this.addMigration(m);
        });
    }
    createImage(image) {
        const url = `/images/${image.name}.webp`;
        if (get(isSafari)) {
            // safari is a fucking piece of crap
            let p = document.createElement("p");
            p.innerText = `${image.description}  `;
            let link = document.createElement("a");
            link.href = url;
            link.innerText = "Bild ansehen";
            link.target = "_blank";
            p.append(link);
            return p;
        }
        else {
            let caption = document.createElement("figcaption");
            caption.innerText = image.description;
            let img = document.createElement("img");
            img.src = url;
            img.alt = image.description;
            let fig = document.createElement("figure");
            fig.append(img, caption);
            return fig;
        }
    }
}
